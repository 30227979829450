<template>
  <div class="p-4 bg-white rounded-xl space-y-4">
    <div :class="class_seq(0)">
      <div class="tw_seq_line1">
        <div :class="class_comfirm(0)"><IconConfirm /></div>
        <div>디스코드 정보를 확인합니다.</div>
      </div>
    </div>

    <div :class="class_seq(1)">
      <div class="tw_seq_line1">
        <div :class="class_comfirm(1)"><IconConfirm /></div>
        <div>지갑을 연결합니다.</div>
      </div>
      <div>
        <button
          :class="class_btn(1)"
          :disabled="disabled_btn(1)"
          @click="onWalletLogin"
          class="tw_seq_btn"
        >
          지갑 로그인
        </button>
      </div>
    </div>

    <div :class="class_seq(2)">
      <div class="tw_seq_line1">
        <div :class="class_comfirm(2)"><IconConfirm /></div>
        <div>디스코드 계정과 지갑주소를 연결합니다.</div>
      </div>
      <div class="text-sm">
        <div class="flex space-x-2">
          <div>디스코드:</div>
          <div>{{ discord_username }}</div>
        </div>
        <div class="flex space-x-2">
          <div>지갑주소:</div>
          <div class="text-xs">{{ wallet_address }}</div>
        </div>
      </div>
      <div>
        <button
          :class="class_btn(2)"
          :disabled="disabled_btn(2)"
          @click="onWalletConnect"
          class="tw_seq_btn"
        >
          디스코드 계정과 지갑 연결
        </button>
      </div>
    </div>

    <div :class="class_seq(3)">
      <div class="tw_seq_line1">
        <IconConfirm />
        <div>연결 완료!</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconConfirm from "@/icons/IconConfirm.vue";

const SS_WAIT = 0;
const SS_DOING = 1;
const SS_DONE = 2;

export default {
  components: {
    IconConfirm,
  },
  data() {
    return {
      seq: 0,
      seq_status: SS_WAIT,
      //
      code: "",
      discord_username: "",
      discord_id: "",
      //
      wallet_address: null,
    };
  },
  async mounted() {
    this.$store.state.show_header = false;

    // http://localhost:8080/dicord_oauth2?code=abc
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get("code");
    console.log("DiscordTools mount", this.code);

    let res;
    res = await this.$store.postAsyncSimple({
      api: "discord_get_name",
      code: this.code,
    });

    if (res.code != 100) {
      this.$store.AlertPost(res);
      this.seq_status = SS_WAIT;
      return;
    }

    this.discord_username = res.data.username;
    this.discord_id = res.data.id;
    this.seq = 1;
    this.seq_status = SS_WAIT;
  },
  methods: {
    class_btn(no) {
      return this.disabled_btn(no) ? "tw_seq_btn_disable" : "tw_seq_btn";
    },
    disabled_btn(no) {
      if (this.seq != no) return true;
      else if (this.seq_status != 0) return true;
      return false;
    },
    class_seq(no) {
      let ret = "tw_seq_active";
      if (this.seq < no) ret = "tw_seq_deactive";
      return ret;
    },
    class_comfirm(no) {
      let ret = "";
      if (this.seq < no) ret = "opacity-25";
      else if (this.seq > no) ret = "";
      else if (this.seq_status == SS_DONE) ret = "";
      else ret = "opacity-25";
      console.log("class_comfirm", no, this.seq, this.seq_status, ret);
      return ret;
    },
    async onWalletLogin() {
      console.log("onWalletLogin", this.code);

      this.seq_status = SS_DOING;

      // 로그인 안되어 있으면 로그인 시킨다.
      if (!this.$store.state.lv2_is_login) {
        this.$store.state.lv2_show_wallet_login = true;
        do {
          await this.$store.waitAsync(1000);
        } while (this.$store.state.lv2_login_doing);
      }

      if (!this.$store.state.lv2_is_login) {
        await this.$store.waitAsync(1000);
        this.$store.Alert("#ERR_NEED_KAIKAS");
        this.seq_status = SS_WAIT;
        return;
      }

      const wallet = this.$store.state.lv2_login_data.wallet;
      this.wallet_address = wallet.selectedAddress;
      // console.log("wallet_address", this.wallet_address);
      this.seq = 2;
      this.seq_status = SS_WAIT;
    },
    async onWalletConnect() {
      console.log("onWalletConnect");

      this.seq_status = SS_DOING;

      let res;
      res = await this.$store.postAsyncSimple({
        api: "discord_wallet_connect",
        code: this.code,
        username: this.discord_username,
        id: this.discord_id,
        wallet_address: this.wallet_address,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        this.seq_status = SS_WAIT;
        return;
      }

      this.seq = 3;
      this.seq_status = SS_DONE;
    },
  },
};
</script>

<style scoped>
.tw_seq_line1 {
  @apply flex items-center space-x-2;
}

.tw_seq_btn {
  @apply px-4 py-2 rounded-full bg-indigo-600 text-sm text-white;
}

.tw_seq_btn_disable {
  @apply px-4 py-2 rounded-full opacity-50 bg-indigo-600 text-sm text-white;
}

.tw_seq_active {
  @apply p-2 space-y-2 rounded-xl border-2 border-black;
}

.tw_seq_deactive {
  @apply p-2 space-y-2 rounded-xl border-2 border border-gray-400 text-gray-400;
}
</style>
